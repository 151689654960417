const BE = 'BE';
const CH = 'CH';
const LU = 'LU';
const CA = 'CA';
const DE = 'DE';
const FR = 'FR';
const NL = 'NL';
const AT = 'AT';
const NZ = 'NZ';
const GB = 'GB';
const UK = 'UK';
const AU = 'AU';
const HK = 'HK';
const SG = 'SG';
const DK = 'DK';
const FI = 'FI';
const IE = 'IE';
const IT = 'IT';
const JP = 'JP';
const NO = 'NO';
const SE = 'SE';

const all = [BE, CH, LU, CA, DE, FR, NL, AT, NZ, GB, AU, HK, SG, DK, FI, IE, IT, JP, NO, SE];

module.exports = {
    BE,
    CH,
    LU,
    CA,
    DE,
    FR,
    NL,
    AT,
    NZ,
    GB,
    UK,
    AU,
    HK,
    SG,
    DK,
    FI,
    IE,
    IT,
    JP,
    NO,
    SE,
    all,
};
