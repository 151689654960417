const English = 'en';
const German = 'de';
const French = 'fr';
const Dutch = 'nl';
const Italian = 'it';
const Swedish = 'sv';
const Danish = 'da';
const Norwegian = 'no';
const Finnish = 'fi';
const Japanese = 'ja';

const all = [English, German, French, Dutch, Italian, Swedish, Danish, Norwegian, Finnish, Japanese];

module.exports = {
    English,
    German,
    French,
    Dutch,
    Italian,
    Finnish,
    Japanese,
    Danish,
    Norwegian,
    Swedish,
    all,
};
