/* eslint-disable @typescript-eslint/no-var-requires */
const { inUseLocales } = require('./Pawshake/Core/Locale/Locale');
const NextI18Next = require('next-i18next').default;

const languageDetectionBasedUponHeader = {
    name: 'language-detection-header',
    lookup: (req) => {
        return req.get('x-cloudfront-region') || 'en-BE';
    },
};

const NextI18NextInstance = new NextI18Next({
    debug: false,
    defaultLanguage: 'en',
    localePath: typeof window === 'undefined' ? 'public/locales' : 'locales',
    saveMissing: false,
    browserLanguageDetection: false,
    fallbackLng: inUseLocales.toFallbackLanguagesObject(),
    otherLanguages: inUseLocales.toString(),
    customDetectors: [languageDetectionBasedUponHeader],
    detection: {
        order: ['language-detection-header'],
    },
});

const { appWithTranslation, withTranslation, useTranslation, Trans, i18n } = NextI18NextInstance;

module.exports = {
    appWithTranslation,
    withTranslation,
    useTranslation,
    Trans,
    i18n,
};

module.exports = NextI18NextInstance;
