/* eslint-disable @typescript-eslint/no-var-requires */
const { BE, AT, AU, CA, CH, DE, DK, FI, FR, GB, HK, IE, IT, JP, LU, NL, NO, NZ, SE, SG } = require('./Country');

const { English, German, French, Dutch, Italian, Finnish, Danish, Norwegian, Swedish } = require('./Language');

const getLocaleFromString = (localeString) => {
    const localeParts = localeString.split('-');
    return new Locale(localeParts[0], localeParts[1]);
};

function Locale(language, country) {
    this.language = language;
    this.country = country;

    this.getLanguage = function () {
        return this.language;
    };

    this.getCountry = function () {
        return this.country;
    };

    this.toString = function () {
        return this.language + '-' + this.country;
    };
}

function Locales(languages, locales) {
    this.languages = languages;
    this.locales = locales;

    this.toString = function () {
        const languages = this.languages.map((language) => language);
        const locales = this.locales.map((locale) => locale.toString());
        return languages.concat(locales);
    };

    this.toFallbackLanguagesObject = function () {
        let fallback = {};
        this.locales.forEach((locale) => {
            fallback[locale.toString()] = [locale.getLanguage(), English];
        });
        return fallback;
    };
}

const EnglishBelgium = new Locale(English, BE);
const GermanBelgium = new Locale(German, BE);
const FrenchBelgium = new Locale(French, BE);
const DutchBelgium = new Locale(Dutch, BE);
const EnglishSwitzerland = new Locale(English, CH);
const GermanSwitzerland = new Locale(German, CH);
const FrenchSwitzerland = new Locale(French, CH);
const ItalianSwitzerland = new Locale(Italian, CH);
const GermanLuxembourg = new Locale(German, LU);
const FrenchLuxembourg = new Locale(French, LU);
const EnglishLuxembourg = new Locale(English, LU);
const FrenchCanada = new Locale(French, CA);
const EnglishCanada = new Locale(English, CA);
const EnglishGermany = new Locale(English, DE);
const EnglishFrance = new Locale(English, FR);
const FrenchFrance = new Locale(French, FR);
const EnglishNetherlands = new Locale(English, NL);
const DutchNetherlands = new Locale(Dutch, NL);
const GermanAustria = new Locale(German, AT);
const EnglishNewZealand = new Locale(English, NZ);
const EnglishGreatBritain = new Locale(English, GB);
const EnglishAustralia = new Locale(English, AU);
const EnglishHongKong = new Locale(English, HK);
const EnglishSingapore = new Locale(English, SG);
const GermanGermany = new Locale(German, DE);
const DanishDenmark = new Locale(Danish, DK);
const FinnishFinland = new Locale(Finnish, FI);
const EnglishIreland = new Locale(English, IE);
const ItalianItaly = new Locale(Italian, IT);
const EnglishJapan = new Locale(English, JP);
const NorwegianNorway = new Locale(Norwegian, NO);
const SwedishSweden = new Locale(Swedish, SE);

const inUseLocales = new Locales(
    [English, German, French, Dutch, Italian, Swedish, Danish, Norwegian, Finnish],
    [
        EnglishBelgium,
        GermanBelgium,
        FrenchBelgium,
        DutchBelgium,
        EnglishSwitzerland,
        GermanSwitzerland,
        FrenchSwitzerland,
        ItalianSwitzerland,
        GermanLuxembourg,
        FrenchLuxembourg,
        EnglishLuxembourg,
        FrenchCanada,
        EnglishCanada,
        EnglishGermany,
        EnglishFrance,
        FrenchFrance,
        EnglishNetherlands,
        DutchNetherlands,
        GermanAustria,
        EnglishNewZealand,
        EnglishGreatBritain,
        EnglishAustralia,
        EnglishHongKong,
        EnglishSingapore,
        GermanGermany,
        DanishDenmark,
        FinnishFinland,
        EnglishIreland,
        ItalianItaly,
        NorwegianNorway,
        SwedishSweden,
        EnglishJapan,
    ]
);

module.exports = {
    GermanAustria,
    GermanBelgium,
    GermanSwitzerland,
    GermanGermany,
    GermanLuxembourg,
    EnglishAustralia,
    EnglishBelgium,
    EnglishCanada,
    EnglishGermany,
    EnglishFrance,
    EnglishGreatBritain,
    EnglishHongKong,
    EnglishIreland,
    EnglishLuxembourg,
    EnglishNetherlands,
    EnglishNewZealand,
    EnglishSwitzerland,
    EnglishSingapore,
    EnglishJapan,
    FrenchBelgium,
    FrenchCanada,
    FrenchFrance,
    FrenchLuxembourg,
    FrenchSwitzerland,
    ItalianItaly,
    ItalianSwitzerland,
    DutchBelgium,
    DutchNetherlands,
    DanishDenmark,
    FinnishFinland,
    NorwegianNorway,
    SwedishSweden,
    inUseLocales,
    Locale,
    getLocaleFromString,
};
